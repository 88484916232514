<template>
  <a
    :href="url"
    class="block no-underline"
    :class="{ 'pointer-events-none': disabled }"
  >
    <span
      class="inline-block border-b px-1 pb-10 text-base uppercase tracking-widest text-grey-dark-01 hover:text-black"
      :class="{
        'text-grey-light-02': dark,
        'hover:text-white': dark,
        'text-grey-mid': disabled,
        'hover:text-grey-mid': disabled && !dark,
        'hover:text-grey-dark-03': disabled && dark,
        'hover:border-pink': !disabled && isRebranding,
      }"
      :style="spanStyle"
    >
      <SpriteSymbol
        v-if="iconStart"
        class="mb-6 inline h-24 w-24 scale-110"
        :class="$slots.default ? 'mr-16' : ''"
        :name="iconStart"
      />
      <slot></slot>
      <SpriteSymbol
        v-if="iconEnd"
        class="mb-6 inline h-24 w-24 scale-110"
        :class="$slots.default ? 'ml-16' : ''"
        :name="iconEnd"
      />
    </span>
  </a>
</template>

<script setup lang="ts">
defineProps<{
  onClick?: () => void
  iconStart?: string
  iconEnd?: string
  disabled?: boolean
  dark?: boolean
  url?: string
  spanStyle?: string
}>()

const isRebranding = await useFeatureFlag()
</script>
